import React, { useState } from 'react';
import { fetchKeyMetrics } from './api';
import './FinancialMetrics.css';

function StockInfo() {
  const [stockSymbol, setStockSymbol] = useState('');
  const [error, setError] = useState('');

  const [name, setName] = useState('');
  const [marketCap, setMarketCap] = useState('');
  const [peRatio, setPeRatio] = useState('');
  const [epsLastFive, setEpsLastFive] = useState([]);
  const [debtEquityRatio, setDebtEquityRatio] = useState('');
  const [dividendYield, setDividendYield] = useState('');
  const [freeCashFlowLastFive, setFreeCashFlowLastFive] = useState([]);
  const [currentRatio, setCurrentRatio] = useState('');
  const [interestCoverageRatio, setInterestCoverageRatio] = useState('');
  const [roe, setRoe] = useState('');
  const [date, setDate] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const data = await fetchKeyMetrics(stockSymbol);

      if (!data || data.length === 0) {
        setError(`Aucune donnée trouvée pour ${stockSymbol}.`);
        return;
      }

      const metrics = data[0];
      setName(metrics.symbol);
      setMarketCap(metrics.marketCap);
      setPeRatio(metrics.peRatio.toFixed(2));

      // On récupère les flux de trésorerie libre pour les 5 dernières années du plus ancien au plus récent
      const freeCashFlowValues = data.slice(0, 5).map((item) => (item.freeCashFlowPerShare * (metrics.marketCap / (metrics.peRatio * metrics.netIncomePerShare))).toFixed(2)).reverse();
      setFreeCashFlowLastFive(freeCashFlowValues);  // Mise à jour de l'état pour FCF

      // On récupère les EPS du plus ancien au plus récent
      const epsValues = data.slice(0, 5).map((item) => item.netIncomePerShare.toFixed(2)).reverse();
      setEpsLastFive(epsValues);  // Mise à jour de l'état pour EPS

      setDebtEquityRatio(metrics.debtToEquity.toFixed(2));
      setDividendYield(metrics.dividendYield.toFixed(2));
      setCurrentRatio(metrics.currentRatio.toFixed(2));
      setInterestCoverageRatio(metrics.interestCoverage.toFixed(2));
      setRoe(metrics.roe.toFixed(2));
      setDate(metrics.date);
    } catch (err) {
      setError(`Erreur lors de la récupération des données : ${err.message}`);
      setName('');
      setMarketCap('');
      setPeRatio('');
      setEpsLastFive([]);
      setDebtEquityRatio('');
      setDividendYield('');
      setFreeCashFlowLastFive([]);
      setCurrentRatio('');
      setInterestCoverageRatio('');
      setRoe('');
    }
  };

  // Fonction pour appliquer une classe de dégradé de couleur
  const getEpsClass = (index, total) => {
    const step = Math.floor(100 / total); // Calcule la variation de la couleur
    return `eps-color-${(index + 1) * step}`;
  };

  // Fonction getClass mise à jour pour gérer les valeurs uniques
  const getClass = (value, min, max, trend = 'increasing') => {
    if (Array.isArray(value)) {
      let isCorrectTrend = true;
      for (let i = 1; i < value.length; i++) {
        const current = parseFloat(value[i]);
        const previous = parseFloat(value[i - 1]);
  
        if (trend === 'increasing' && current < previous) {
          isCorrectTrend = false;
          break;
        } else if (trend === 'decreasing' && current > previous) {
          isCorrectTrend = false;
          break;
        }
      }
      return isCorrectTrend ? 'good' : 'bad';
    } else if (typeof value === 'number') {
      return (value >= min && value <= max) ? 'good' : 'bad';
    } else {
      return 'neutral';
    }
  };

  // Fonction pour formater les nombres en milliards et millions
  const formatNumber = (num) => {
    if (Math.abs(num) >= 1e9) {
      return (num / 1e9).toFixed(2) + ' Mrd';  // Utilise "Mrd" pour milliards
    } else if (Math.abs(num) >= 1e6) {
      return (num / 1e6).toFixed(2) + ' M';    // Utilise "M" pour millions
    } else {
      return num.toLocaleString('fr-FR');
    }
  };
  
  

  const getResults = () => {
    return [
      { 
        name: 'PE Ratio',
        value: peRatio ? parseFloat(peRatio) : 'N/A',
        unit: 'fois',
        description: "Mesure la valeur de l'action par rapport à ses bénéfices. Elle doit se trouver entre <strong>10 et 20</strong>",
        min: 10, max: 20,
        className: getClass(parseFloat(peRatio), 10, 20)
      },
      {
        name: 'EPS', 
        value: epsLastFive && epsLastFive.length > 0 ? (
          <span>
            {epsLastFive.map((eps, index) => (
              <span key={index} className={getEpsClass(index, epsLastFive.length)}>
                {eps} {index < epsLastFive.length - 1 ? ' ; ' : ''}
              </span>
            ))}
          </span>
        ) : 'N/A',
        unit: '',
        description: "L'Earnings Per Share est le bénéfice de l'entreprise divisé par le nombre d'actions. On doit trouver une <strong>croissance régulière d'année en année</strong>",
        className: getClass(epsLastFive, 0, Infinity, 'increasing')
      },
      { 
        name: 'Debt/Equity Ratio',
        value: debtEquityRatio ? parseFloat(debtEquityRatio) : 'N/A',
        unit: 'fois',
        description: "Niveau d'endettement par rapport aux capitaux propres. Il doit être inférieur à <strong>1.0</strong>",
        min: 0, max: 1.0,
        className: getClass(parseFloat(debtEquityRatio), 0, 1.0)
      },
      { 
        name: 'Rendement des Dividendes',
        value: dividendYield ? parseFloat(dividendYield * 100) : 'N/A',
        unit: '%',
        description: "Dividendes payés par rapport au prix de l'action. Il doit être entre <strong>2% et 6%</strong>",
        min: 2, max: 6,
        className: getClass(parseFloat(dividendYield * 100), 2, 6)
      },
      { 
        name: 'Flux de trésorerie libre',
        value: freeCashFlowLastFive && freeCashFlowLastFive.length > 0 ? (
          <span>
            {freeCashFlowLastFive.map((flow, index) => (
              <span key={index} className={getEpsClass(index, freeCashFlowLastFive.length)}>
                {formatNumber(flow)} {index < freeCashFlowLastFive.length - 1 ? ' ; ' : ''}
              </span>
            ))}
          </span>
        ) : 'N/A',
        unit: 'USD',
        description: "Argent disponible après les dépenses d'exploitation et d'investissement. Il doit être <strong>positif et croissant</strong>",
        className: getClass(freeCashFlowLastFive, 0, Infinity, 'increasing')
      },
      { 
        name: 'Ratio de liquidité courante',
        value: currentRatio ? parseFloat(currentRatio) : 'N/A',
        unit: 'fois',
        description: "Capacité de l'entreprise à rembourser ses obligations à court terme avec ses actifs courants. Doit être supérieur à <strong>1.0</strong>",
        min: 1.0, max: Infinity,
        className: getClass(parseFloat(currentRatio), 1.0, Infinity)
      },
      { 
        name: 'Ratio de couverture des intérêts',
        value: interestCoverageRatio ? parseFloat(interestCoverageRatio) : 'N/A',
        unit: 'fois',
        description: "Capacité de l'entreprise à couvrir ses intérêts sur la dette avec ses bénéfices. Ça doit être supérieur à <strong>1.5</strong>",
        min: 1.5, max: Infinity,
        className: getClass(parseFloat(interestCoverageRatio), 1.5, Infinity)
      },
      { 
        name: 'Retour sur capitaux propres (ROE)',
        value: roe ? parseFloat(roe * 100).toFixed(2) : 'N/A',
        unit: '%',
        description: "Rendement généré sur les capitaux propres. Doit être au-dessus de <strong>15%</strong>",
        min: 15, max: Infinity,
        className: getClass(parseFloat(roe * 100), 15, Infinity)
      },
    ];
  };  

  return (
    <div>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Calcul de la valeur intrinsèque</h2>
          <p className="mt-4 text-gray-500">
            La valeur intrinsèque est une estimation de la véritable valeur d'une entreprise, indépendamment de son prix de marché actuel. Elle est calculée en analysant les fondamentaux financiers, comme les revenus, les bénéfices, la croissance et les perspectives d'avenir, pour déterminer la performance économique réelle et le potentiel de croissance de l'entreprise.
          </p>
          <div className='flex justify-center mt-8'>
            <form onSubmit={handleSubmit} className="max-w-sm">
              <div className="relative mb-4 flex w-full flex-wrap items-stretch">
                <input 
                  type="text" 
                  className="relative border border-solid m-x-3 p-2" 
                  value={stockSymbol}
                  onChange={(e) => setStockSymbol(e.target.value)}
                  placeholder="ex: AAPL"
                />
                <div className="input-group-append">
                  <button type="submit" className="p-2 bg-sky-700 text-white">Rechercher</button>
                </div>
              </div>
            </form>
          </div>
          {name && (
            <div className='m-5'>
              <h4 className='m-3 text-2xl'>{name}</h4>
              <h4 className='mx-3 text-lg mb-1'>Capitalisation : {formatNumber(marketCap)} USD</h4>
              <p className="mt-1 mb-4 text-xs text-gray-500">Données du dernier rapport annuel financier : {date}</p>
              <dl className="grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-2 sm:gap-y-10 lg:gap-x-8">
                {getResults().map((result, index) => (
                  <div key={index} className={`flex items-center border-t border-gray-200 metric`}>
                    <div className="pt-4 max-w-md">
                      <div className={`status-ball ${result.className}`}></div>
                      {result.name}
                      <dt className="font-medium text-gray-900 ml-2">
                        {result.value} {result.unit}
                      </dt>
                      <dd className="mt-2 text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: result.description }}></dd>
                    </div>
                  </div>
                ))}
              </dl>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StockInfo;
